<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-5 px-1 px-md-5">
			<!-- NAVIGATION BAR -->

			<div class="col-lg-3 d-none d-lg-block">
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label font-weight-bolder text-dark">
						SRF <i class="flaticon2-user text-primary clickable ml-2" v-b-tooltip="'Show User SRFs'" @click="search(currentUser.username)" />
					</span>
					<span class="text-muted mt-3 font-weight-bold font-size-sm"> {{ filteredList.length }} {{ activeStatus }} Service Requests </span>
				</h3>
			</div>
			<div class="col-12 col-md-6 col-lg-4 col-xl-4 d-flex align-items-center ">
				<div class="search-bar d-flex">
					<i class="flaticon-search search-icon text-primary" />
					<b-form-input v-model="searchText" placeholder="Search" class="px-11" debounce="200"></b-form-input>
					<span v-if="searchCol.length === 0" class="svg-icon svg-icon-md svg-icon-primary search-col p-2">
						<b-dropdown size="sm" variant="link" no-caret dropleft no-flip toggle-class="custom-v-dropdown">
							<template #button-content>
								<inline-svg src="/media/svg/icons/Text/Filter.svg" v-b-tooltip.hover="'Filter By Column'"></inline-svg>
							</template>
							<div class="navi">
								<b-dropdown-text tag="div">
									<span class="dropdown-header">Filter by Column</span>
								</b-dropdown-text>
								<b-dropdown-text tag="div" class="navi-separator"></b-dropdown-text>
								<b-dropdown-item @click="setFilter(['id'])">ID</b-dropdown-item>
								<b-dropdown-item @click="setFilter(['customer'])">Customer</b-dropdown-item>
								<b-dropdown-item @click="setFilter(['site'])">Site</b-dropdown-item>
								<b-dropdown-item @click="setFilter(['requestor'])">Requestor</b-dropdown-item>
								<b-dropdown-item @click="setFilter(['updated'])">Last Updated</b-dropdown-item>
								<b-dropdown-item @click="setFilter(['assigned'])">Assigned</b-dropdown-item>
							</div>
						</b-dropdown>
					</span>
					<span
						v-else
						class="search-col btn btn-light-danger btn-sm px-2"
						style="margin-top: 1px;"
						@click="
							setFilter([]);
							searchText = '';
						"
					>
						Clear
						<i class="flaticon-circle pl-1" style="font-size: 1em;" />
					</span>
				</div>
				<div class="col-4 d-md-none text-right px-0">
					<router-link :to="{ name: 'newsrf' }">
						<button class="btn btn-outline-primary"><i class="flaticon-plus" /> New SRF</button>
					</router-link>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-5 col-xl-4 card-toolbar justify-content-center justify-content-md-end mt-5 mt-md-0 px-0">
				<ul class="nav nav-pills nav-pills-sm nav-dark-75">
					<li class="nav-item">
						<a class="nav-link py-2 px-4 pointer" :class="[activeStatus === 'All' ? 'active' : '']" @click="changeView('All')">
							All
						</a>
					</li>
					<li class="nav-item">
						<a
							class="nav-link py-2 px-4 pointer nav-pending"
							:class="[activeStatus === 'Pending' ? 'active' : '']"
							@click.stop.prevent="changeView('Pending')"
						>
							Pending
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link py-2 px-4 pointer nav-new" :class="[activeStatus === 'New' ? 'active' : '']" @click="changeView('New')">
							New
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link py-2 px-4 pointer nav-working" :class="[activeStatus === 'Working' ? 'active' : '']" @click="changeView('Working')">
							Working
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link py-2 px-4 pointer nav-closed" :class="[activeStatus === 'Closed' ? 'active' : '']" @click="changeView('Closed')">
							Closed
						</a>
					</li>
				</ul>
			</div>
		</div>
		<!-- END NAVIGATION BAR -->

		<!-- START SRF TABLE -- DESKTOP -->
		<div class="card-body pt-3 pb-0">
			<ITCSpinner :loaded="loaded">
				<b-table
					hover
					responsive="md"
					:items="filteredList"
					:fields="fields"
					sort-by="updated"
					:sort-desc="true"
					:current-page="currentPage"
					per-page="50"
					tbody-tr-class="rowClass"
					show-empty
					id="desktop-table"
				>
					<template #empty>
						<div class="text-center mt-15">
							<h1 class="font-weight-light">No {{ activeStatus }} SRFs</h1>
						</div>
					</template>
					<template #head(action)>
						<div class="text-right mr-2">
							<router-link :to="{ name: 'newsrf' }">
								<button class="btn btn-outline-primary btn-sm"><i class="flaticon-plus" /> New SRF</button>
							</router-link>
						</div>
					</template>
					<template #cell(site)="data">
						<div class="d-flex align-items-center">
							<div class="mr-2">
								<router-link :to="{ name: 'srf', params: { id: data.item.id } }">
									<span class="btn btn-icon btn-light btn-sm">
										<span class="svg-icon svg-icon-md svg-icon-primary">
											<inline-svg src="/media/svg/icons/Files/File.svg"></inline-svg>
										</span>
									</span>
								</router-link>
							</div>
							<div>
								<div>
									<router-link :to="{ name: 'srf', params: { id: data.item.id } }">
										<span class="font-weight-bold">{{ data.value }}</span>
									</router-link>
									<span v-if="data.item.DID" class="pl-2">
										<a :href="'https://portal.itcglobal.com/noc/app/remote/' + data.item.DID" target="_blank">
											<i class="flaticon-analytics text-primary" v-b-tooltip="'Go to NOC Portal'" />
										</a>
									</span>
								</div>
								<div>
									<span class="text-dark-65">{{ data.item.description }}</span>
								</div>
								<div>
									<span class="clickable subtext" @click="search(data.item.customer, 'customer')">{{ data.item.customer }}</span>
								</div>
							</div>
						</div>
					</template>
					<template #cell(id)="data">
						<span class="text-dark-50">{{ data.value }}</span>
					</template>
					<template #cell(requestor)="data">
						<span class="clickable" @click="search(data.value, 'requestor')" v-b-tooltip="'Search ' + data.value">{{ data.value }}</span>
					</template>
					<template #cell(updator)="data">
						<span class="clickable" @click="search(data.value, 'updator')" v-b-tooltip="'Search ' + data.value">{{ data.value }}</span>
					</template>
					<template #cell(assigned)="data">
						<span v-if="data.value" class="clickable" @click="search(data.value, 'assigned')" v-b-tooltip="'Search ' + data.value">{{
							data.value
						}}</span>
						<span v-else :class="[{ clickable: adminControls }, 'text-muted']" @click="adminControls && openAssignModal(data.item)">Unassigned</span>
					</template>
					<template #cell(status)="data">
						<span
							class="label label-lg label-inline"
							:class="[{ pointer: adminControls && data.item.status !== 'Closed' }, `label-light-${statuses[data.value].color}`]"
							@click="adminControls && data.item.status !== 'Closed' && changeStatus(data.item)"
						>
							{{ data.value }}
						</span>
					</template>
					<template #cell(action)="data">
						<div class="d-flex justify-content-end mr-3">
							<div>
								<span class="btn btn-icon btn-light btn-sm mr-3" v-b-tooltip.hover="'Download'">
									<span class="svg-icon svg-icon-md svg-icon-info" @click="download(data.item)">
										<inline-svg src="/media/svg/icons/Files/DownloadedFile.svg"></inline-svg>
									</span>
								</span>
							</div>
							<router-link :to="{ name: 'editsrf', params: { id: data.item.id } }" v-if="data.item.status !== 'Closed'">
								<span class="btn btn-icon btn-light btn-sm mr-3" v-b-tooltip.hover="'Edit'">
									<span class="svg-icon svg-icon-md svg-icon-info">
										<inline-svg src="/media/svg/icons/Communication/Write.svg"></inline-svg>
									</span>
								</span>
							</router-link>
							<b-dropdown
								size="sm"
								variant="link"
								toggle-class="custom-v-dropdown"
								no-caret
								right
								no-flip
								v-b-tooltip.hover="'Actions'"
								ref="srfActions"
							>
								<template v-slot:button-content>
									<span class="btn btn-icon btn-light btn-sm" @click="getVersions(data.item.id)">
										<span class="svg-icon svg-icon-md svg-icon-primary">
											<inline-svg src="/media/svg/icons/General/Settings-1.svg"></inline-svg>
										</span>
									</span>
								</template>
								<div class="navi navi-hover min-w-md-250px">
									<b-dropdown-text tag="div">
										<span class="dropdown-header">SRF Actions</span>
									</b-dropdown-text>
									<b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>
									<b-dropdown-text tag="div" class="navi-item" v-if="adminControls && data.item.status !== 'Closed'">
										<a href="#" class="navi-link" @click="changeStatus(data.item)">
											<span class="navi-icon"><i class="flaticon-share"></i></span>
											<span class="dropdown-text">Change Status</span>
										</a>
									</b-dropdown-text>
									<b-dropdown-text tag="div" class="navi-item" v-if="adminControls && data.item.status !== 'Closed'">
										<a href="#" class="navi-link" @click="openAssignModal(data.item)">
											<span class="navi-icon"><i class="flaticon-users-1"></i></span>
											<span class="dropdown-text" v-if="data.item.assigned">Reassign</span>
											<span class="dropdown-text" v-else>Assign</span>
										</a>
									</b-dropdown-text>
									<b-dropdown-text tag="div" class="navi-item">
										<router-link :to="{ name: 'newsrf', params: { id: data.item.id } }" class="navi-item">
											<a href="#" class="navi-link">
												<span class="navi-icon"><i class="flaticon2-copy"></i></span>
												<span class="dropdown-text">Copy to New SRF</span>
											</a>
										</router-link>
									</b-dropdown-text>
									<b-dropdown-text tag="div" class="navi-item">
										<router-link :to="{ name: 'srf', params: { id: data.item.id } }" class="navi-item">
											<a href="#" class="navi-link">
												<span class="navi-icon"><i class="flaticon2-file-1"></i></span>
												<span class="dropdown-text">View</span>
											</a>
										</router-link>
									</b-dropdown-text>
									<b-dropdown-text tag="div" class="navi-item" @click="download(data.item)">
										<a href="#" class="navi-link">
											<span class="navi-icon"><i class="flaticon-download"></i></span>
											<span class="dropdown-text">Download</span>
										</a>
									</b-dropdown-text>
									<b-dropdown-text tag="div" class="navi-item" @click="$bvModal.show('versions')">
										<a href="#" class="navi-link">
											<span class="navi-icon"><i class="flaticon2-calendar-8"></i></span>
											<span class="dropdown-text">Version History</span>
											<span class="navi-label">
												<span class="label label-success label-rounded">{{ versions.length }}</span>
											</span>
										</a>
									</b-dropdown-text>
									<b-dropdown-text tag="div" class="navi-item" v-if="adminControls && data.item.status !== 'Closed'">
										<a href="#" class="navi-link delete-navi" @click="rejectSRF(data.item)">
											<span class="navi-icon"><i class="flaticon2-reply delete"></i></span>
											<span class="dropdown-text">Reject</span>
										</a>
									</b-dropdown-text>
									<b-dropdown-text tag="div" class="navi-item" v-if="adminControls && data.item.status !== 'Closed'">
										<a href="#" class="navi-link delete-navi" @click="deleteSRF(data.item)">
											<span class="navi-icon"><i class="flaticon2-trash delete"></i></span>
											<span class="dropdown-text">Delete</span>
										</a>
									</b-dropdown-text>
								</div>
							</b-dropdown>
						</div>
					</template>
				</b-table>

				<!-- MOBILE TABLE -->
				<b-table
					id="mobile-table"
					hover
					responsive="md"
					:items="filteredList"
					:fields="mobileFields"
					sort-by="updated"
					:sort-desc="true"
					:current-page="currentPage"
					per-page="50"
					tbody-tr-class="rowClass"
					@row-clicked="showMobileActions"
					show-empty
				>
					<template #empty>
						<div class="text-center mt-15">
							<h1 class="font-weight-light">No {{ activeStatus }} SRFs</h1>
						</div>
					</template>
					<template #cell(id)="data">
						<span class="text-dark-50">{{ data.value }}</span>
					</template>
					<template #cell(site)="data">
						<div class="d-flex align-items-center">
							<div>
								<div>
									<span>{{ data.value }}</span>
								</div>
								<div>
									<span class="clickable text-dark-50">{{ data.item.customer }} | {{ data.item.requestor }}</span>
								</div>
								<div>
									<span v-if="data.item.assigned">
										<span class="text-dark-50">Assigned to </span>
										{{ data.item.assigned }}
									</span>
									<span v-else class="text-dark-50">
										Unassigned
									</span>
								</div>
							</div>
						</div>
					</template>
				</b-table>
			</ITCSpinner>
			<div class="d-flex justify-content-center" v-if="filteredList.length > 50">
				<b-pagination class="pagination" v-model="currentPage" :total-rows="filteredList.length" per-page="50" pills />
			</div>
		</div>
		<!-- END SRF TABLE -->

		<!-- SRF CLOSEOUT DOC -->
		<div v-if="submitCloseoutPDF" style="position: absolute; z-index: -10; opacity:0;">
			<SrfCloseout :fields="closeoutFields" @done="hideCloseoutDoc" />
		</div>

		<!-- MODALS SECTION -->

		<!-- STATUS CHANGE MODAL -->
		<b-modal id="changeStatus" :title="changeStatusTitle" centered v-if="focusSrf" size="lg" @hide="newStatus = null" no-stacking>
			<div class="d-flex flex-column align-items-center">
				<div class="status-timeline mt-3">
					<template v-for="(item, status) in statuses">
						<div
							:key="status"
							class="status-timeline-item align-items-start"
							:class="[statuses[focusSrf.status].value > item.value ? 'text-muted' : 'text-dark-75']"
							@click="newStatus = focusSrf.status == status ? null : status"
						>
							<div class="status-timeline-label ">
								<span class="label label-lg label-inline" v-bind:class="`label-light-${item.color}`">
									{{ status }}
								</span>
							</div>
							<div class="status-timeline-badge">
								<i class="icon-xl" :class="item.badge"></i>
							</div>
							<div class="status-timeline-content pl-3 clickable">
								<span
									v-if="focusSrf.status == status"
									class="font-weight-bold text-dark-75 label label-lg label-inline"
									:class="{ 'selected-status': !newStatus }"
								>
									Current Status
								</span>
								<span
									v-else-if="newStatus && newStatus === status"
									class="font-weight-bolder text-dark-75 label label-lg label-inline selected-status"
								>
									New Status
								</span>
								<span v-else> Update</span>
							</div>
						</div>
					</template>
				</div>
				<div class="mt-14">
					<h4 v-if="!newStatus">Current Status: {{ focusSrf.status }}</h4>
					<h4 v-else>Update Status to {{ newStatus }}</h4>
				</div>
			</div>
			<template #modal-footer>
				<b-button v-if="newStatus == 'Closed'" variant="success" v-b-modal.setDID>Close SRF</b-button>
				<b-button
					v-else
					variant="primary"
					:disabled="!newStatus || settingStatus"
					@click="saveStatus(focusSrf.id, newStatus)"
					:class="{ 'spinner spinner-white spinner-right': settingStatus }"
					>Update Status</b-button
				>
			</template>
		</b-modal>
		<!-- END CHANGE STATUS MODAL -->

		<!-- START SET DID MODAL -->
		<b-modal id="setDID" title="Link to NMS Modem" no-stacking @show="linkModem" centered no-close-on-backdrop>
			<div v-if="loadingModems" class="text-center">
				<b-spinner />
				<h4>Getting modem data</h4>
			</div>
			<div v-else>
				<b-form-group label="Select NMS Modem Name to Link SRF to NOC Portal" label-for="modemDID">
					<b-form-select type="text" id="modemDID" v-model="modemDID">
						<b-form-select-option v-for="(modem, i) in modems" :key="i" :value="modem.DID">{{ modem.name }}</b-form-select-option>
					</b-form-select>
				</b-form-group>
			</div>
			<template #modal-footer>
				<b-button variant="primary" v-b-modal.closeout>Continue</b-button>
			</template>
		</b-modal>
		<!-- END SET DID MODAL -->

		<!-- START CLOSEOUT MODAL -->
		<b-modal
			id="closeout"
			title="Closeout Checklist - Engineering"
			size="lg"
			ok-title="Submit"
			no-close-on-backdrop
			@ok="closeSRF(focusSrf.id)"
			@show="getSRF(focusSrf.id)"
		>
			<div class="row  px-7">
				<div class="col-12 pb-5 text-dark-50">Please complete the following checklist:</div>
				<div class="col-12">
					<form>
						<b-form-group label="Handover to Operations Date" label-for="handover">
							<b-form-datepicker type="text" id="handover" v-model="closeout.handoverDate" />
						</b-form-group>
						<b-form-checkbox v-model="closeout.commissioned" value="1" unchecked-value="0" class="mb-3">
							All <strong>Customer Services</strong> commissioned (Voice, Data, etc.) and verified with onsite contact
						</b-form-checkbox>
						<b-form-checkbox v-model="closeout.configSharepoint" value="1" unchecked-value="0" class="mb-3">
							All <strong>configurations</strong> uploaded to <strong>Sharepoint</strong>. Copy and paste link below
						</b-form-checkbox>
						<b-form-checkbox v-model="closeout.drawingsSharepoint" value="1" unchecked-value="0" class="mb-3">
							All <strong>drawings</strong> uploaded to <strong>Sharepoint</strong>. Copy and paste link below
						</b-form-checkbox>
						<b-form-checkbox v-model="closeout.solarwinds" value="1" unchecked-value="0" class="mb-3">
							All offshore devices added to <strong>Solarwinds</strong>
						</b-form-checkbox>
						<b-form-checkbox v-model="closeout.solarwindsSnmp" value="1" unchecked-value="0" class="mb-3">
							All network connection points in Solarwinds have the proper <strong>SNMP monitoring enabled</strong>
						</b-form-checkbox>
						<b-form-checkbox v-model="closeout.ipam" value="1" unchecked-value="0" class="mb-3">
							All IPs recorded in <strong>IPAM</strong>
						</b-form-checkbox>
						<b-form-checkbox v-model="closeout.tacacs" value="1" unchecked-value="0" class="mb-3">
							All offshore devices configured for <strong>TACACS</strong> where possible
						</b-form-checkbox>
						<b-form-checkbox v-model="closeout.passwords" value="1" unchecked-value="0" class="mb-3">
							All <strong>local passwords</strong> recorded on Sharepoint for equipment without TACACS
						</b-form-checkbox>
						<b-form-checkbox v-model="closeout.cisco" value="1" unchecked-value="0" class="mb-3">
							All <strong>Cisco devices</strong> added to NCM (Network Configuration Manager)
						</b-form-checkbox>
						<b-form-checkbox v-model="closeout.notifications" value="1" unchecked-value="0" class="mb-7">
							Completion notification sent to <strong>NOC Manager</strong> and <strong>Project Manager</strong>
						</b-form-checkbox>
						<b-form-group label="Sharepoint Link" label-for="sharepointLink" class="mb-3">
							<b-form-input type="text" id="sharepointLink" v-model="closeout.sharepointLink" />
						</b-form-group>
						<b-form-group label="Notes">
							<b-form-textarea
								id="notes"
								v-model="closeout.notes"
								placeholder="E.g. project timeline and deliverables against proposal"
								rows="5"
								max-rows="6"
							/>
						</b-form-group>
					</form>
				</div>
			</div>
		</b-modal>
		<!-- END CLOSEOUT MODAL -->

		<!-- START ASSIGNMENT MODAL -->
		<b-modal id="assign" title="Assign an Engineer" centered @hide="assignedEngineer = null">
			<div>
				<b-form-group label="Select Engineer">
					<b-form-select v-model="assignedEngineer">
						<b-select-option v-for="eng in engineers" :value="eng" :key="eng.id">{{ eng.name }}</b-select-option>
					</b-form-select>
				</b-form-group>
			</div>
			<template #modal-footer>
				<div class="text-right">
					<b-button variant="primary" @click="assign" :class="{ 'spinner spinner-white spinner-right': assigning }" :disabled="assigning">
						Assign
					</b-button>
				</div>
			</template>
		</b-modal>

		<!-- START VERSIONS MODAL -->
		<b-modal id="versions" title="Version History" ok-only>
			<div>
				<b-table-simple>
					<b-thead>
						<b-tr>
							<b-th>Updated</b-th>
							<b-th>Updated By</b-th>
							<b-th></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="(version, i) in versions" :key="i">
							<b-td style="vertical-align: middle;">{{ version.created }}</b-td>
							<b-td style="vertical-align: middle;">{{ version.username }}</b-td>
							<b-td style="vertical-align: middle;"><b-button variant="success" @click="downloadVersion(version.data)">View</b-button></b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-modal>

		<!-- START REJECT MODAL -->
		<b-modal id="reject" :title="`Reject SRF #${rejecting.id}`" no-stacking centered no-close-on-backdrop size="lg">
			<div>
				<h4 class="font-weight-normal mb-5">
					Reject SRF created by <span class="font-weight-bold">{{ rejecting.requestor }}</span> for
					<span class="font-weight-bold">{{ rejecting.site }}</span>
				</h4>
			</div>
			<div>
				<b-form-group label="Please provide a reason for rejecting the SRF">
					<b-form-textarea rows="7" v-model="rejectReason"></b-form-textarea>
				</b-form-group>
			</div>
			<div v-if="rejectError.error">
				<span class="text-danger">{{ rejectError.msg }}</span>
			</div>
			<div>
				This SRF will be moved to a pending status and the requestor will be emailed with the explanation.
			</div>
			<template #modal-footer>
				<div class="text-right">
					<b-button variant="primary" @click="submitReject()" :class="{ 'spinner spinner-white spinner-right': submittingReject }">
						Submit
					</b-button>
				</div>
			</template>
		</b-modal>
		<!-- END REJECT MODAL -->

		<!-- START MOBILE ACTIONS MODAL -->
		<b-modal id="mobile-actions" centered hide-footer no-stacking @hide="mobileClicked = null">
			<template #modal-header="{ close }" v-if="mobileClicked">
				<i class="flaticon-circle close-icon" @click="close()" />
				<div class="font-size-h1">
					{{ mobileClicked.site }}
					<div class="font-size-h4 text-dark-50">Updated {{ mobileClicked.updated }} by {{ mobileClicked.updator }}</div>
				</div>
			</template>
			<div v-if="mobileClicked">
				<ul>
					<li class="navi-item" v-if="adminControls && mobileClicked.status !== 'Closed'">
						<a href="#" class="navi-link" @click="changeStatus(mobileClicked)">
							<span class="navi-icon"><i class="flaticon-share"></i></span>
							<span class="dropdown-text">Change Status</span>
						</a>
					</li>
					<li class="navi-item" v-if="adminControls && mobileClicked.status !== 'Closed'">
						<a href="#" class="navi-link" @click="openAssignModal(mobileClicked)">
							<span class="navi-icon"><i class="flaticon-users-1"></i></span>
							<span class="dropdown-text" v-if="mobileClicked.assigned">Reassign</span>
							<span class="dropdown-text" v-else>Assign</span>
						</a>
					</li>
					<li class="navi-item">
						<router-link :to="{ name: 'newsrf', params: { id: mobileClicked.id } }" class="navi-link">
							<a href="#" class="navi-link">
								<span class="navi-icon"><i class="flaticon2-copy"></i></span>
								<span class="dropdown-text">Copy to New SRF</span>
							</a>
						</router-link>
					</li>
					<li class="navi-item">
						<router-link :to="{ name: 'srf', params: { id: mobileClicked.id } }" class="navi-link">
							<a href="#" class="navi-link">
								<span class="navi-icon"><i class="flaticon2-file-1"></i></span>
								<span class="dropdown-text">View</span>
							</a>
						</router-link>
					</li>
					<li class="navi-item" @click="download(mobileClicked)">
						<a href="#" class="navi-link">
							<span class="navi-icon"><i class="flaticon-download"></i></span>
							<span class="dropdown-text">Download</span>
						</a>
					</li>
					<li class="navi-item" @click="$bvModal.show('versions')">
						<a href="#" class="navi-link">
							<span class="navi-icon"><i class="flaticon2-calendar-8"></i></span>
							<span class="dropdown-text">Version History</span>
							<span class="label label-success label-rounded ml-2">{{ versions.length }}</span>
						</a>
					</li>
					<li class="navi-item" v-if="adminControls && mobileClicked.status !== 'Closed'">
						<a href="#" class="navi-link delete-navi" @click="deleteSRF(data.item)">
							<span class="navi-icon"><i class="flaticon2-trash delete"></i></span>
							<span class="dropdown-text">Delete</span>
						</a>
					</li>
				</ul>
			</div>
		</b-modal>

		<!-- END MODALS -->
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import generateSrfPdf from '@/view/content/lib/srfPDF';
import { mapGetters } from 'vuex';

export default {
	name: 'SrfList',
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		SrfCloseout: () => import('@/view/content/widgets/srf/CloseoutForm.vue'),
	},
	data() {
		return {
			loaded: false,
			list: null,
			activeStatus: 'New',
			statuses: {
				Pending: {
					color: 'info',
					value: 0,
					badge: 'fa fa-genderless text-info',
				},
				New: { color: 'primary', value: 1, badge: 'fa fa-genderless text-primary' },
				Working: { color: 'warning', value: 2, badge: 'fa fa-genderless text-warning' },
				Closed: { color: 'success', value: 3, badge: 'fa fa-genderless text-success' },
			},
			fields: [
				{ key: 'site', sortable: true, thClass: 'headerClass min-20-header' },
				{ key: 'id', label: 'ID', sortable: true, thClass: 'headerClass' },
				{ key: 'requestor', sortable: true, thClass: 'headerClass' },
				{ key: 'updator', label: 'Last Updated By', sortable: true, thClass: 'headerClass' },
				{ key: 'assigned', sortable: true, thClass: 'headerClass' },
				{ key: 'status', thClass: 'headerClass' },
				{ key: 'updated', label: 'Last Updated', sortable: true, thClass: 'headerClass' },
				{ key: 'action', label: '' },
			],
			mobileFields: [
				{ key: 'id', label: 'ID', sortable: true, thClass: 'headerClass max-20-header' },
				{ key: 'site', sortable: true, thClass: 'headerClass min-20-header' },
			],
			mobileClicked: null,
			currentPage: 1,
			searchText: '',
			searchCol: [],
			focusSrf: { status: 'New' },
			changeStatusTitle: '',
			newStatus: null,
			settingStatus: false,
			engineers: null,
			customAssigneeOptions: {
				accountID:805,
				email:"cbachle@itcglobal.com",
				name:"Chris Bachle",
				username:"cbachle"
			},
			assigning: false,
			assignedEngineer: null,
			loadingModems: false,
			modems: null,
			modemDID: null,
			fetchedSrf: null,
			closeout: {},
			closing: false,
			submitCloseoutPDF: false,
			closeoutFields: null,
			versions: [],
			rejecting: [],
			rejectReason: null,
			submittingReject: false,
			rejectError: {
				error: false,
				msg: null,
			},
		};
	},
	computed: {
		...mapGetters(['currentUser']),
		adminControls() {
			return this.currentUser.role === 'admin' || this.currentUser.role === 'noc';
		},
		filteredList() {
			if (!this.list) return [];
			//no searchText return list based on status selected
			if (this.searchText === '') {
				return this.activeStatus == 'All' ? this.list : this.list.filter(srf => srf.status === this.activeStatus);
			} else {
				//filter by searchtext (and column if set)
				return this.list.filter(srf => {
					if (srf.status === this.activeStatus || this.activeStatus === 'All') {
						if (this.searchCol.length > 0) {
							return this.searchCol.some(col => ((srf[col] && srf[col].toString()) || '').toLowerCase().includes(this.searchText.toLowerCase()));
						} else {
							return Object.values(srf).some(
								val =>
									val !== undefined &&
									val !== null &&
									val
										.toString()
										.toLowerCase()
										.includes(this.searchText.toLowerCase())
							);
						}
					} else {
						return false;
					}
				});
			}
		},
	},
	methods: {
		search(text, column) {
			this.searchText = text;
			this.searchCol = column ? [column] : [];
		},
		changeView(status) {
			this.activeStatus = status;
			this.currentPage = 1;
			this.$router.replace({ query: { ...this.$route.query, status: status } });
		},
		setFilter(col) {
			this.searchCol = col;
			this.$router.replace({ query: { ...this.$route.query, filter: col } });
		},
		download(srf) {
			this.$http.get(`srf/${srf.id}`).then(resp => {
				generateSrfPdf(resp.data.data.data);
			});
		},
		getSRF(id) {
			this.$http.get(`srf/${id}`).then(resp => {
				this.fetchedSrf = resp.data.data;
			});
		},
		getVersions(id) {
			this.$http.get(`srf/${id}/history`).then(resp => {
				this.versions = resp.data.data;
			});
		},
		downloadVersion(data) {
			generateSrfPdf(data);
		},
		showMobileActions(row) {
			this.$bvModal.show('mobile-actions');
			this.mobileClicked = row;
		},
		openAssignModal(srf) {
			this.focusSrf = srf;
			if (srf.assigned) {
				this.assignedEngineer = this.engineers.find(e => e.name === srf.assigned);
			}
			this.$bvModal.show('assign');
		},
		assign() {
			this.assigning = true;
			this.$http.post(`srf/${this.focusSrf.id}/assign`, { assigned_to: this.assignedEngineer.username }).then(resp => {
				if (resp.data.data.result == 1) {
					this.focusSrf.assigned = this.assignedEngineer.name;
					this.focusSrf.status = 'Working';
					this.assigning = false;
					this.$bvModal.hide('assign');
				}
			});
		},
		changeStatus(srf) {
			if (srf.status !== 'Closed') {
				this.changeStatusTitle = 'Change SRF Status - ' + srf.site;
				this.$bvModal.show('changeStatus');
				this.focusSrf = srf;
			}
		},
		saveStatus(id, status) {
			this.settingStatus = true;
			this.$http.post(`srf/${id}/status`, { status: status }).then(resp => {
				this.focusSrf.status = status;
				this.$bvModal.hide('changeStatus');
				this.focusSrf = null;
				this.settingStatus = false;
			});
		},
		linkModem() {
			this.loadingModems = true;
			this.$http.query('srf/detectdid', { params: { name: this.focusSrf.site } }).then(resp => {
				this.modems = resp.data.data.modems;
				if (resp.data.data.found) {
					this.modemDID = resp.data.data.found.DID;
				}
				this.loadingModems = false;
			});
		},
		closeSRF(id) {
			//This function does:
			// - Change status to closed
			// - Add SRF PDF to NOC Portal/srfupload table
			// - Save Engineering responses to Closeout checklist
			// - If NOC approval not needed, add closeout PDF to srfupload
			this.closing = true;
			this.saveCloseout();

			this.fetchedSrf.data.modem_DID = this.modemDID;
			this.fetchedSrf.status = 'Closed';
			this.$http.post(`srf/${id}/status`, this.fetchedSrf).then(resp => {
				if (resp.data.data.message == 'Closed') {
					this.addToNoc(this.fetchedSrf.data);
					this.focusSrf.status = 'Closed';
					this.focusSrf = null;
				}
			});
		},
		saveCloseout() {
			this.closeout.srfid = this.fetchedSrf.SRFID;
			this.closeout.engineeringComplete = 1;
			this.closeout.client = this.fetchedSrf.data.customer_name;
			this.closeout.site = this.fetchedSrf.data.site_name;
			this.closeout.commissionedDate = this.fetchedSrf.data.service_start_date;
			if (this.fetchedSrf.data.request_type === 'Change to Service') {
				this.closeout.nocComplete = 1;
				this.closeout.username = this.currentUser.username;
				this.closeout.nocUser = 'Portal';
				this.closeout.closed = 1;
				this.closeoutFields = { ...this.closeout, modem_DID: this.modemDID };
				this.submitCloseoutPDF = true;
			}
			this.$http.post(`srf/closeout`, this.closeout).then(resp => {
				console.log(resp.data);
			});
		},
		hideCloseoutDoc() {
			console.log('done');
			this.submitCloseoutPDF = false;
		},
		addToNoc(srf) {
			let { customer_name, site_name, modem_type, antenna_serial_number, modem_DID } = srf;
			let filename = [customer_name, site_name, modem_type, antenna_serial_number, '.pdf']
				.filter(n => n !== '')
				.join('_')
				.replaceAll(' ', '_')
				.replaceAll('/', '-')
				.replaceAll('\\', '-');
			generateSrfPdf(srf, srfpdf => {
				srfpdf.getBuffer(pdf => {
					this.$http
						.post('srf/uploadnoc', {
							srfpdf: pdf,
							DID: modem_DID,
							filename: filename,
							username: this.currentUser.username,
						})
						.then(resp => {
							console.log('SRF attached to NOC Portal');
						});
				});
			});
		},
		deleteSRF(srf) {
			this.$bvModal
				.msgBoxConfirm(`Delete of SRF for ${srf.customer} at ${srf.site}`, {
					title: 'Confirm Delete',
					okVariant: 'danger',
					okTitle: 'YES',
					cancelTitle: 'NO',
					footerClass: 'p-2',
				})
				.then(val => {
					if (val) {
						this.$http.post(`srf/${srf.id}/delete`).then(resp => {
							if (resp.status == 200) {
								console.log(resp.data);
								this.list = this.list.filter(s => s.id !== srf.id);
							}
						});
					}
				});
		},
		rejectSRF(srf) {
			this.rejecting = srf;
			this.$bvModal.show('reject');
		},
		submitReject() {
			if (this.rejectReason == '' || this.rejectReason == null) {
				this.rejectError.error = true;
				this.rejectError.msg = 'Please fill in a reason';
			} else {
				this.submittingReject = true;
				this.$http
					.post(`srf/${this.rejecting.id}/reject`, { reason: this.rejectReason })
					.then(resp => {
						if (resp.status == 200) {
							this.submittingReject = false;
							this.rejectReason = null;
							this.rejectError.error = false;
							this.rejectError.msg = null;
							this.rejecting.status = 'Pending';
							this.$bvModal.hide('reject');
						}
					})
					.catch(err => {
						console.log('error', err);
						this.rejectError.error = true;
						this.rejectError.msg = err;
					});
			}
		},
	},
	created() {
		if (this.currentUser.role == 'user' && !this.currentUser.showsrf) {
			this.$router.push({ name: 'dash' });
		}
		this.loaded = false;
		let calls = [this.$http.get('srf/list')];
		if (this.adminControls) {
			calls.push(this.$http.get('engineers/all'));
		}
		Promise.all(calls).then(resp => {
			// this.engineers = Object.freeze(resp[1]?.data?.data);
			this.engineers = resp[1]?.data?.data;
			if (this.engineers) {
				this.engineers.push(this.customAssigneeOptions);
				this.engineers.sort((a, b) => a.name.localeCompare(b.name));
				this.engineers = Object.freeze(this.engineers);
				resp[0].data.data.forEach(srf => {
					srf.assigned = this.engineers.find(e => e.username === srf.assigned)?.name;
				});
			}
			this.list = resp[0].data.data;
			this.loaded = true;
		});
		if (this.$route.query.q) this.searchText = this.$route.query.q;
		if (this.$route.query.filter) this.searchCol = this.$route.query.filter.split(',');
		if (this.$route.query.status) this.activeStatus = this.$route.query.status;
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'SRF List' }]);
	},
	watch: {
		searchText(newVal) {
			if (newVal !== this.$route.query.q) {
				let params = { status: this.activeStatus };
				if (newVal) params.q = newVal;
				if (this.searchCol.length > 0) params.filter = this.searchCol.join(',');
				this.$router.replace({ query: params }).catch(e => {});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep #mobile-table {
	display: none;
}
.pointer {
	cursor: pointer;
}
.clickable {
	cursor: pointer;

	&:hover {
		color: $info;
	}
}
::v-deep .rowClass td {
	padding: 5px 5px;
	vertical-align: middle;
}
::v-deep .headerClass {
	color: rgb(151, 149, 149);
	text-transform: uppercase;
	vertical-align: middle;
	padding: 0 5px;
}
::v-deep.header-5 {
	width: 50px;
}
::v-deep .min-20-header {
	width: 30%;
}
.nav-link.active.nav-pending {
	background: $info-light;
	color: $info;
}
.nav-link.active.nav-new {
	background: $primary-light;
	color: $primary;
}
.nav-link.active.nav-working {
	background: $warning-light;
	color: $warning;
}
.nav-link.active.nav-closed {
	background: $success-light;
	color: $success;
}
.dropdown-header {
	font-weight: 500;
	font-size: 1em;
	color: rgba(16, 10, 66, 0.8);
}
.dropdown-text {
	font-weight: 400;
}
.search-bar {
	width: 100%;
	position: relative;
}
.search-icon {
	position: absolute;
	top: 8px;
	left: 10px;
}
.search-col {
	position: absolute;
	top: 2px;
	right: 5px;
	cursor: pointer;
}
.subtext {
	color: grey;
	font-size: 0.9em;
}
a.delete-navi:hover span,
a.delete-navi:hover span.navi-icon i.delete {
	color: $danger;
}
.status-timeline {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: 200.5px;
		width: 4px;
		top: 3px;
		bottom: 4px;
		background-color: $gray-200;
	}

	.status-timeline-item {
		display: flex;
		align-items: center;
		position: relative;
		margin-bottom: 2.5rem;

		&:last-child {
			margin-bottom: 0px;
		}

		.status-timeline-label {
			width: 200px;
			flex-shrink: 0;
			font-size: 1rem;
			font-weight: 500;
			position: relative;
			color: $dark-75;
			text-align: right;
			padding-right: 15px;
		}

		.status-timeline-badge {
			flex-shrink: 0;
			background: white;
			width: 13px;
			height: 13px;
			border-radius: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 1;
			position: relative;
			margin-top: 3px;
			margin-left: -0.5rem;
			padding: 3px !important;
			border: 6px solid #fff !important;

			span {
				display: block;
				border-radius: 100%;
				width: 6px;
				height: 6px;
				background-color: $gray-200;
			}
		}

		.status-timeline-content {
			width: 190px;
			flex-grow: 1;

			.selected-status {
				border: 1px solid $primary;
			}
		}
	}
}

#mobile-actions {
	padding: 10px;

	.close-icon {
		position: absolute;
		top: 10px;
		right: 15px;
		font-size: 1.75rem;
	}
	.close-icon:hover,
	.close-icon:active {
		color: $danger;
	}
	ul {
		list-style-type: none;

		li.navi-item {
			text-decoration: none;
			padding: 10px;
			font-size: 1.75rem;
			display: flex;
			.navi-link {
				width: 100%;
				.navi-icon {
					padding: 0 15px 0 0;
					i {
						font-size: 1em;
					}
				}
			}
		}
		li.navi-item:hover,
		li.navi-item:active {
			background: #f3f6f9;
		}
	}
}
@media screen and (max-width: 767px) {
	::v-deep #desktop-table {
		display: none;
	}
	::v-deep #mobile-table {
		display: block;
	}
	::v-deep .max-20-header {
		width: 1%;
	}
}
</style>
